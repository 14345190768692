/** @jsx jsx */
import { jsx } from "theme-ui"

const SquareList = props => (
  <ul
    sx={{
      m: 0,
      mb: ["2.6rem", "3.2rem", "4rem"],
      pl: "2rem",
      listStyleType: "square",
      "> li": {
        mb: '1rem',
        "&::marker": {
          color: "#d5d7d8",
          fontSize: '2rem',
          lineHeight: 0,
        },
      },
    }}
    {...props}
  />
)

export default SquareList
